import { Injectable } from '@angular/core';
import { AppcmsService } from './appcms.service';
import { HttpClient } from '@angular/common/http';
import { CacheService } from './cache.service';
import { TaxiService } from './taxi.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryareaService {

  blIsModal: boolean = false;

  modal: any;

  constructor(
    public AppCMS: AppcmsService,
    public cache: CacheService,
    public taxi: TaxiService,
  ) {

  }

  getCoordinates()
  {
    return new Promise((resolve, reject) => {
      let cacheKey: string = 'deliveryArea_' + this.AppCMS.getApiUrl();
      let fromCache: cacheItem = this.cache.get(cacheKey, (60 * 60 * 24 * 7));
      
      if(fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.taxi.getSettings()
        .then((taxiSettings: taxiSettings) => {
          if(taxiSettings.deliveryarea) {
            this.cache.set(cacheKey, taxiSettings.deliveryarea);
          }
          resolve(taxiSettings && taxiSettings.deliveryarea ? taxiSettings.deliveryarea : null);
        })
        .catch(reject);
      }
    });

  }

  getModal() {
    return this.modal;
  }

  isModal(blIsModal = null) {
    if(blIsModal !== null) {
      this.blIsModal = blIsModal;
    }
    return this.blIsModal;
  }

  setModal(modal: any) {
    this.modal = modal;
  }

}



