import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from '../events.service';
import { OpeningsService } from '../openings.service';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.page.html',
  styleUrls: ['./closed.page.scss'],
})
export class ClosedPage implements OnInit {
  
  modalCtrl: any;
  
  openings: opening[];

  constructor(
    public events: EventsService,
    public navParams: NavParams,
    public openingsService: OpeningsService,
  ) {
    this.modalCtrl = this.navParams.get('modalCtrl');

    this.loadOpenings();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  loadOpenings() {
    this.openingsService.getAll()
    .then((openings: opening[]) => {
      this.openings = openings;
    })
    .catch(error => {
      this.events.publish('error', error);
    });
  }
  
  ngOnInit() {
  }

}
