import { Injectable } from '@angular/core';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings = window.localStorage.getItem('settings') ? JSON.parse(window.localStorage.getItem('settings')) : {};
  
  constructor(
    public cache: CacheService,
    // public oneSignal: OneSignal,
  ) {

  }

  get(key: any) {
    return this.settings[key];
  }

  // getAll() {
  //   return new Promise((resolve, reject) => {
  //     this.oneSignal.getPermissionSubscriptionState()
  //     .then(pushState => {
  //       this.settings.push = pushState;
  //       resolve(this.settings);
  //     })
  //     .catch(reject);
  //   });
  // }

  set(key: string, value: any) {
    this.settings[key] = value;
    this.setSettings(this.settings);
  }

  setSettings(settings: any) {
    window.localStorage.setItem('settings', JSON.stringify(settings));
  }

}
