import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { LogoService } from '../logo.service';

@Component({
  selector: 'app-logininfo',
  templateUrl: './logininfo.page.html',
  styleUrls: ['./logininfo.page.scss'],
})
export class LogininfoPage implements OnInit {

  modalCtrl: any;

  constructor(
    public logo: LogoService,
    public navParams: NavParams,
    public navCtrl: NavController,
  ) {
    this.modalCtrl = this.navParams.get('modalCtrl');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ionViewWillEnter() {
    this.logo.hide();
    this.logo.lock();
  }

  ionViewWillLeave() {
    this.logo.unlock();
    this.logo.show();
  }

  ngOnInit() {
  }

  register() {
    this.dismiss();
    this.navCtrl.navigateForward('/register');
  }

}
