import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  shouldShowRules: any = {};

  constructor(
    public userService: UserService,
    public settings: SettingsService,
  ) {
    this.shouldShowRules = {
      'home': {
        'loginInfo': () => {
          return new Promise((resolve, reject) => {
            let user = this.userService.getUser() || {};
            resolve(
              !user.uid && !this.settings.get('home:loginInfo:dismissed')
            );
          });
        },
      },
      'search': {
        'intro': () => {
          return new Promise((resolve, reject) => {
            resolve(!this.userService.getPlz());
          });
        }
      }
    };
  }

  shouldShow(modalId: string, view: string) {
    return new Promise((resolve, reject) => {
      if( !this.shouldShowRules ||
          !this.shouldShowRules.hasOwnProperty(view) ||
          (this.shouldShowRules.view && !this.shouldShowRules.view.hasOwnProperty(modalId))
      ) {
        return false;
      }
      this.shouldShowRules[view][modalId]().then((should: boolean) => {
        resolve(should);
      }).catch(reject);
    });
  }

}
