import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppcmsService } from '../appcms.service';
import { DeliveryareaService } from '../deliveryarea.service';
import { EventsService } from '../events.service';

declare var google: any;

@Component({
  selector: 'app-deliveryareamodal',
  templateUrl: './deliveryareamodal.page.html',
  styleUrls: ['./deliveryareamodal.page.scss'],
})
export class DeliveryareamodalPage implements OnInit {

  coordinates: any;

  deliveryArea: any;

  map: any;

  marker: any;

  markerData: any = {
    lat: 51.8,
    lng: 6.6,
  };

  modalCtrl: any;

  constructor(
    public AppCMS: AppcmsService,
    public deliveryAreaService: DeliveryareaService,
    public events: EventsService,
    public zone: NgZone,
    public _modalCtrl: ModalController,
  ) {
    this.modalCtrl = (this.deliveryAreaService.getModal() || this._modalCtrl);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ionViewDidEnter() {
    this.loadCoordinates();
  }

  ionViewWillLeave() {
    this.deliveryAreaService.isModal(false);
  }
  
  ngOnInit() {
  }

  loadCoordinates() {
    this.deliveryAreaService.getCoordinates()
    .then(coordinates => {
      this.coordinates = coordinates;
      this.loadMap();
    })
    .catch(error => {
      this.events.publish('error', error);
    });
  }

  loadMap() {

    try {
      let mapPosition = new google.maps.LatLng(this.markerData.lat, this.markerData.lng);
      
      if(!this.map) {
        //this.map = GoogleMaps.create($('.map').last()[0], mapOptions);
        this.zone.run(() => {
          let map = document.getElementById('deliveryAreaMap');
          this.map = new google.maps.Map(map, {
            center: mapPosition,
            zoom: 11,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          });

          this.deliveryArea = new google.maps.Polygon({
            paths: this.coordinates,
            strokeColor: '#d33939',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: '#ffffff',
            fillOpacity: 0
          });

          google.maps.Polygon.prototype.my_getBounds=function(){
            var bounds = new google.maps.LatLngBounds();
            this.getPath().forEach((element: any, index: number) => { bounds.extend(element); });
            return bounds;
          }
          
          this.deliveryArea.setMap(this.map);
          
          let center = this.deliveryArea.my_getBounds().getCenter();
          this.map.setCenter(center);
        });
      } else {
        this.zone.run(() => {
          this.map.setCenter(mapPosition);
        });
      }
    } catch(e) {
      console.warn('e 3', e);
    }

  }

}
