import { Injectable } from '@angular/core';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  latLng: any;

  constructor(

  ) {

  }

  getLatLng() {
    return this.latLng;
  }

  setLatLng(latLng: any) {
    this.latLng = new google.maps.LatLng(latLng.lat, latLng.lng);
  }

}