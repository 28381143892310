import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

  constructor(public navctrl: NavController, public modalCtrl : ModalController) { }

  ngOnInit() {
  }

  close(){
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  logout(){
    this.modalCtrl.dismiss({
      'dismissed': true
    });
    window.localStorage.clear();
    // window.localStorage.
    this.navctrl.navigateRoot('/authenticate');
  }

}
